import Vue from 'vue'
import { ACCESS_TOKEN } from "@/store/mutation-types"
const getters = {
  showMenu: state => state.user.showMenu,
  showMenuMobile: state => state.user.showMenuMobile,
  token: state => {
    state.user.token = Vue.ls.get(ACCESS_TOKEN)
    return state.user.token
  },
  nickname: state => state.user.nickname,
  headimgurl: state => state.user.headimgurl,
  type: state => state.user.type,
  uuid: state => state.user.uuid,
  cartNumber: state => state.cart.number,
}

export default getters
