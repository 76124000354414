<template>
  <div id="home">
    <div class="div">
      <div class="inquiries" v-loading="loadingInquiries">
        <div class="title">
          Inquiries
          <a @click="go('/monthlyAnalytics')">More info<i class="el-icon-more"></i></a>
        </div>
        <div class="list">
          <div class="item" @click="go('/callInquiries')">
            <div>
              <h1>Calls</h1>
              <p v-if="inquiry && inquiry.calls">{{inquiry.calls.month_call_count}}<span>{{inquiry.calls.change_rate >= 0 ? '+' : '-'}}{{inquiry.calls.change_rate * 100}}%</span></p>
            </div>
            <div class="icon">
              <i class="el-icon-phone"></i>
            </div>
          </div>
          <div class="item">
            <div>
              <h1>New Inquiries</h1>
              <p v-if="inquiry">{{inquiry.new_inquiries}}</p>
            </div>
            <div class="icon">
              <i class="el-icon-s-promotion"></i>
            </div>
          </div>
          <div class="item">
            <div>
              <h1>New Customer Rate</h1>
              <p v-if="inquiry && inquiry.new_customers">{{inquiry.new_customers.month_new_customer_rate}}<span>{{inquiry.new_customers.change_rate >= 0 ? '+' : '-'}}{{inquiry.new_customers.change_rate * 100}}%</span></p>
            </div>
            <div class="icon">
              <i class="el-icon-user-solid"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="analytics">
        <div class="title">
          Social media analytics
          <a @click="go('/socialMedia')">More info<i class="el-icon-more"></i></a>
        </div>
        <div class="list">
          <div class="item">
            <img src="../assets/imgs/icons/icon_pro1.png">
            <h1>Instagram</h1>
            <div>
              <span>NEW</span>
              30 days
            </div>
            <p>Likes<b>50</b></p>
            <p>Comments<b>50</b></p>
            <p>Followers<b>50</b></p>
          </div>
          <div class="item">
            <img src="../assets/imgs/icons/icon_pro2.png">
            <h1>Facebook</h1>
            <div>
              <span>NEW</span>
              30 days
            </div>
            <p>Likes<b>50</b></p>
            <p>Comments<b>50</b></p>
            <p>Followers<b>50</b></p>
          </div>
          <div class="item">
            <img src="../assets/imgs/icons/icon_pro3.png">
            <h1>TikTok</h1>
            <div>
              <span>NEW</span>
              30 days
            </div>
            <p>Likes<b>50</b></p>
            <p>Comments<b>50</b></p>
            <p>Followers<b>50</b></p>
          </div>
          <div class="item">
            <img src="../assets/imgs/icons/icon_pro4.png">
            <h1>LinkedIn</h1>
            <div>
              <span>NEW</span>
              30 days
            </div>
            <p>Likes<b>50</b></p>
            <p>Comments<b>50</b></p>
            <p>Followers<b>50</b></p>
          </div>
        </div>
      </div>
    </div>
    <div class="seo" v-loading="loading">
      <div class="title">
        SEO Anaytics Report
        <a @click="go('/seo')">More info<i class="el-icon-more"></i></a>
      </div>
      <div class="img">
        <img :src="getImg(seoMain.public_files_uuid)">
        <div>
          <p>Displaying SEO Analytics Report for:</p>
          <p>{{getMonStr(seoMain.report_month)}} {{seoMain.report_year}}</p>
          <b>{{seoMain.company_name}}</b>
          <p>{{seoMain.address1}}, {{seoMain.address2}}, {{seoMain.state}} {{seoMain.zip_code}}, {{seoMain.city}}</p>
          <a :href="seoMain.seo_website_url" target="_blank">{{seoMain.seo_website_url}}</a>
        </div>
      </div>
      <div class="list">
        <div class="item">
          <i class="el-icon-user"></i>
          <div>
            Unique Visitor
            <b>{{seoMain.unique_visitor}}</b>
          </div>
        </div>
        <div class="item">
          <i class="el-icon-view"></i>
          <div>
            Page Views
            <b>{{seoMain.page_views}}</b>
          </div>
        </div>
        <div class="item">
          <i class="el-icon-upload2"></i>
          <div>
            Average Engagement Rate
            <b>{{seoMain.average_engagement_rate}}</b>
          </div>
        </div>
        <div class="item">
          <i class="el-icon-star-off"></i>
          <div>
            Average Session Duration
            <b>{{seoMain.average_session_duration}}</b>
          </div>
        </div>
      </div>
    </div>

    <div class="reputation">
      <div class="title">
        Reputation
        <a @click="go('/reputationReport')">More info<i class="el-icon-more"></i></a>
      </div>
      <div class="list">
        <div class="item">
          <img src="../assets/imgs/icons/icon_pro6.png"/>
          <div class="left">
            <h1>Google</h1>
            <p><b>67</b>Reviews</p>
            Based on 30 days
          </div>
          <div class="right">
            <a>Average Rating</a>
            <el-rate
              v-model="value" disabled>
            </el-rate>
            <span>4.5 / 5</span>
          </div>
        </div>
        <div class="item">
          <img src="../assets/imgs/icons/icon_pro5.png"/>
          <div class="left">
            <h1>Yelp</h1>
            <p><b>67</b>Reviews</p>
            Based on 30 days
          </div>
          <div class="right">
            <a>Average Rating</a>
            <el-rate
              v-model="value" disabled>
            </el-rate>
            <span>4.5 / 5</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi } from '@/network/manageApi';
  import moment from 'moment/moment';
  export default {
    name: 'Home',
    components: {

    },
    data() {
      return {
        value:4.5,
        loading:false,
        loadingInquiries:false,
        seoMain:{},
        inquiry:{}
      }
    },
    computed: {

    },
    created(){
      this.loading = true
      this.loadingInquiries = true
      const params = {
        year:0,
        month:0
      }
      getActionApi('/seo/main',params).then((res) => {
        this.seoMain = res.data
      }).catch((err) => {
        this.$message.error(err.response.data.details.data.message)
      }).finally((res) => {
        this.loading = false
      });

      getActionApi('/inquiry/main').then((res) => {
        this.inquiry = res.data
      }).catch((err) => {
        this.$message.error(err.response.data.details.data.message)
      }).finally((res) => {
        this.loadingInquiries = false
      });
    },
    methods:{
      getImg(url){
        return `${process.env.VUE_APP_BASE_API}/public-file/public/`+url
      },
      go(url) {
        this.$router.push(url);
      },
      getMonStr(mon){
        const monStr = ["January","February","March","April","May","June","July","August","September","October","November","December"]
        return monStr[mon-1]
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  #home .div:nth-child(1){display: grid;grid-template-columns: 2fr 5fr;gap: 30px;}
  .inquiries{display: flex;flex-direction: column;}
  .inquiries .list{height:280px;display: grid;grid-template-rows: 1fr 1fr 1fr;gap: 14px;}
  .inquiries .list .item{background-color: #ffffff;cursor: pointer;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:0 20px;display: flex;align-items: center;justify-content: space-between;}
  .inquiries .list .item div{display: flex;flex-direction: column;}
  .inquiries .list .item div h1{font-size:12px;color:#A0AEC0;font-weight: normal;margin-bottom:5px;}
  .inquiries .list .item div p{font-size: 20px;color:#2D3748;font-weight: bold;display: flex;align-items:flex-end;line-height: 1;}
  .inquiries .list .item div p span{font-size: 14px;color:#48BB78;margin-left:10px;}
  .inquiries .list .item .icon{width:45px;height:45px;flex-shrink: 0;background:#5856D6;border-radius:12px;display: flex;justify-content: center;align-items: center;color:#ffffff;font-size:20px;}
  .analytics{display: flex;flex-direction: column;}
  .analytics .list{display: grid;gap: 14px;grid-template-columns: 1fr 1fr 1fr 1fr;}
  .analytics .list .item{height:280px;background-color: #ffffff;padding:0 30px;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;display: flex;flex-direction: column;align-items: center;justify-content: center;}
  .analytics .list .item img{height:60px;width:60px;}
  .analytics .list .item h1{color:#2D3648;font-size:20px;margin:10px 0;}
  .analytics .list .item div{width:100%;display: flex;margin:10px 0;justify-content: space-between;font-size: 12px;color:#A0ABC0;}
  .analytics .list .item div span{background-color: #B2F5EA;border-radius: 6px;width: 40px;height: 20px;display: flex;align-items: center;justify-content: center;color:#234E52;}
  .analytics .list .item p{width: 100%;color:#717D96;font-size:14px;line-height: 2;}
  .analytics .list .item p b{color:#2D3648;margin-left:10px;}
  .seo{display: flex;flex-direction: column;}
  .seo .img{background-color: #ffffff;margin-bottom:14px;padding:20px;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;}
  .seo .img img{max-height:60px;}
  .seo .img div{border-top:1px solid #A0AEC0;margin-top:10px;padding-top:10px;display: flex;flex-direction: column;}
  .seo .img p{font-size: 12px;color:#4A5468;line-height: 2;}
  .seo .img b{color:#4A5468;font-size:16px;padding:10px 0;}
  .seo .img a{color:#4299E1;font-size:12px;text-decoration: underline;cursor: pointer;}
  .seo .list{display: grid;gap: 14px;grid-template-columns: 1fr 1fr 1fr 1fr;}
  .seo .list .item{height:80px;background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:0 30px;display: flex;align-items: center;}
  .seo .list .item i{color:#5856D6;font-size:30px;margin-right: 20px;}
  .seo .list .item div{display: flex;flex-direction: column;font-size: 12px;color:#A0AEC0;}
  .seo .list .item div b{font-size: 20px;color: #2D3748;}
  .reputation{display: flex;flex-direction: column;}
  .reputation .list{display: grid;gap:30px;grid-template-columns: 1fr 1fr;}
  .reputation .list .item{height:134px;background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:0 20px;display: flex;align-items: center;}
  .reputation .list .item img{width:76px;height:76px;flex-shrink: 0;margin-right:15px;}
  .reputation .list .item .left{width: 100%;display: flex;flex-direction: column;color:#A0ABC0;font-size: 12px;}
  .reputation .list .item .left h1{font-size: 20px;color: #A0ABC0;}
  .reputation .list .item .left p{font-size:14px;color:#717D96;margin-bottom:5px;}
  .reputation .list .item .left p b{font-size: 20px;color:#2D3648;margin-right: 10px;}
  .reputation .list .item .right{flex-shrink: 0;display: flex;align-items: center;flex-direction: column;}
  .reputation .list .item .right a{color:#A0AEC0;font-size: 12px;margin-bottom: 5px;}
  .reputation .list .item .right span{color:#4A5468;font-size: 14px;font-weight: bold;margin-top:5px;}
  .title{display: flex;justify-content: space-between;align-items: center;height:50px;font-size:20px;color:#2D3648;font-weight: bold;padding:0 10px;}
  .title a{font-size:14px;color:#717D96;font-weight: normal;cursor: pointer;}
  .title a i{color:#717D96;font-size: 14px;margin-left:10px;}
  @media (max-width: 768px) {
    #home .div:nth-child(1){grid-template-columns: 1fr;}
    .inquiries .list{height:288px;display: grid;grid-template-rows: 1fr 1fr 1fr;grid-template-columns: 1fr;gap: 14px;}
    .seo .list{gap: 14px;grid-template-columns: 1fr 1fr;}
    .analytics .list{gap: 14px;grid-template-columns: 1fr 1fr;grid-template-rows: 1fr 1fr;}
    .reputation .list{gap:20px;grid-template-columns: 1fr;grid-template-rows: 1fr 1fr;}
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    #home .div:nth-child(1){grid-template-columns: 1fr;}
    .inquiries .list{height:98px;display: grid;grid-template-columns: 1fr 1fr 1fr;grid-template-rows: 1fr;gap: 14px;}
    .seo .list{height:174px;display: grid;gap: 14px;grid-template-columns: 1fr 1fr;}
  }
</style>
