<template>
  <el-dialog title="Inquiry Detail" :width="dialogWidth" :visible="visible" :close-on-click-modal="false" @close="visible = false">
    <div class="form" v-loading="form_loading">
      <div class="left">
        <div class="div">
          <h1>Incoming Call Overview</h1>
          <div class="flex">
            <a>{{info.phone_number_from}}</a>
            <a>{{timeDifference(info.call_start_datetime,info.call_end_datetime)}}</a>
          </div>
          <div class="flex border">
            First Inquiry Date/Time
            <b>{{moment(info.call_start_datetime).format('MM/DD/YY, HH:mm:ss A')}}</b>
          </div>
          <div class="flex border">
            Category
            <b>{{info.category}}</b>
          </div>
          <div class="flex border">
            Conversion
            <b v-if="info.conversion">{{info.conversion?'Yes':'No'}}</b>
          </div>
          <div class="flex border">
            Caller Name
            <b>{{info.caller_name}}</b>
          </div>
          <div class="flex border">
            Respondent Name
            <b>{{info.callee_name}}</b>
          </div>
        </div>
        <div class="div">
          <h1>Quality Control</h1>
          <div class="flex border">
            Customer Satisfaction
            <el-rate
              v-model="info.customer_satisfaction" disabled>
            </el-rate>
          </div>
          <div class="flex border">
            Issue Resolved
            <el-rate
              v-model="info.issued_resolved" disabled>
            </el-rate>
          </div>
          <div class="flex border">
            Comment
            <p>{{info.comment}}</p>
          </div>
        </div>
      </div>
      <div class="div">
        <h1>Summary</h1>
        <p>{{info.summary}}</p>
        <h1>Keywords</h1>
        <div class="label">
          <el-tag v-for="item,index in info.key_words" :key="index">{{item}}</el-tag>
        </div>
        <h1>Call Transcription</h1>
        <p>{{info.call_transcript}}</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Vue from 'vue';
import { getActionApi } from '@/network/manageApi';
import moment from 'moment/moment';
export default {
  name: 'Show',
  components: {},
  data() {
    return {
      dialogWidth:'1088px',
      value:5,
      visible: false,
      info: {},
      form_loading: false,
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  computed: {

  },
  created() {
  },
  methods: {
    moment,
    init(id) {
      this.visible = true
      this.form_loading = true
      this.info = {}
      getActionApi('inquiry/call-inquiry/'+id).then((res) => {
        this.info = res.data
      }).catch((err) => {

      }).finally((res) => {
        this.form_loading = false
      })
    },
    timeDifference(time1, time2) {
      const duration = moment.duration(moment(time2).diff(moment(time1)));
      let result = '';
      if (duration.days() > 0) {
        result += `${duration.days()} d`;
      }
      if (duration.hours() > 0) {
        if (result) {
          result += ` `;
        }
        result += `${duration.hours()} h`;
      }
      if (duration.minutes() > 0) {
        if (result) {
          result += ` `;
        }
        result += `${duration.minutes()} min`;
      }
      if (duration.seconds() > 0) {
        if (result) {
          result += ` `;
        }
        result += `${duration.seconds()} sec`;
      }
      return result;
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 1088 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  .form{display: grid;grid-template-columns:1fr 1fr;}
  .form .flex{display: flex;justify-content: space-between;min-height:60px;align-items: center;font-size: 14px;}
  .border{border-bottom: 1px solid #EDF2F7;}
  .left{border-right: 1px solid #EDF2F7;padding-right:30px;margin-right: 30px;}
  .form a{font-size: 18px;font-weight: bold;color: #5856D6;width: 100%;text-align: center;}
  h1{font-size: 18px;margin:20px 0;}
  b{font-size: 18px;}
  p{font-size: 14px;}
  .label{display:flex;}
  .label span{margin:0 10px 10px 0;}
  @media (max-width: 768px) {
     .form{display: grid;grid-template-columns:1fr;}
     .left{border-right: 0px solid #EDF2F7;padding-right:0;margin-right: 0;}
  }
</style>
