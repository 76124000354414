<template>
  <div id="home">
    <div class="div">
      <div class="title">
        Monthly Analytics
        <span @click="handleDownload">All Download</span>
      </div>
      <div class="time">
        <span @click="initList(-1)"><i class="el-icon-arrow-left"></i></span>
        {{year}}
        <span @click="initList(1)"><i class="el-icon-arrow-right"></i></span>
      </div>
      <div id="basic_dashboard" v-loading="loading"></div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import * as echarts from 'echarts';
  import moment from 'moment/moment';
  import { getActionApi } from '@/network/manageApi';
  export default {
    name: 'MonthlyAnalytics',
    components: {

    },
    data() {
      return {
        loading:false,
        year:0,
        myChart:null,
        list:[]
      }
    },
    computed: {

    },
    mounted(){
      this.year = moment().format('YYYY')
      this.initList(0)
      window.addEventListener('resize', this.handleResize);
    },
    created(){

    },
    methods:{
      handleResize() {
        if (this.myChart) {
          this.myChart.resize();
        }
      },
      initList(ind){
        this.year = parseInt(this.year) + ind
        this.list = [0,0,0,0,0,0,0,0,0,0,0,0]
        this.loading = true
        getActionApi('/report/monthly-anlytics/'+this.year).then(res => {
          if(res.code == 200){
            let list = res.data
            for(let i = 0; i < list.length; i++){
              if(list[i].month == i+1){
                let count = 0;
                if(list[i].email != null)
                  count += list[i].email
                if(list[i].calls != null)
                  count += list[i].calls
                if(list[i].social_media_direct_message != null)
                  count += list[i].social_media_direct_message
                this.$set(this.list,i,count);
              }
            }
            this.initChart()
          }
        })
      },
      initChart(){
        var chartDom = document.getElementById('basic_dashboard');
        this.myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#5856D6',
            '#F56565'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            formatter:function(params){
              const monStr = ["January","February","March","April","May","June","July","August","September","October","November","December"]
              return `${monStr[params[0].dataIndex]} : ${params[0].data}`
            }
          },
          legend: {
            data: ['Total', 'Targeted'],
            bottom:'2%'
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
            }
          ],
          yAxis: [
            {
              max: function (value) {
                return value.max < 50 ? 50 : value.max;
              },
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Total',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(88, 86, 214, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(88, 86, 214, 0)'
                  }
                ])
              },
              data: this.list
            },
            {
              type: 'line',
              name:'Targeted',
              markLine: {
                symbol: 'none',
                data: [
                  {
                    yAxis: 20,
                    label: {
                      show: false,
                    },
                    lineStyle: {
                      type: 'solid',
                      width: 2,
                      color: '#F56565',
                    },
                  }
                ],
              }
            }
          ]
        };
        this.myChart.setOption(option)
        this.loading = false
      },
      handleDownload() {
        const img = new Image()
        let mapChart = this.$echarts.init(document.getElementById("basic_dashboard"))
        img.src = mapChart.getDataURL({
          type: "png",
          pixelRatio: 1,
          backgroundColor: "#fff",
        })
        img.onload = () => {
          const canvas = document.createElement("canvas")
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext("2d")
          if (ctx) {
            ctx.drawImage(img, 0, 0, img.width, img.height)
            const link = document.createElement("a")
            link.download = `Monthly Analytics-${this.year}.png`
            link.href = canvas.toDataURL("image/png", 0.9)
            document.body.appendChild(link)
            link.click()
            link.remove()
          }
        }
      },
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  #home .div{background-color: #ffffff;padding: 20px;display: flex;flex-direction: column;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  #home .div .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  #home .div .title span{height:32px;padding:0 20px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;}
  #basic_dashboard{height:450px;}
  .time{display: flex;justify-content: flex-end;align-items: center;margin-top:30px;color:#5856D6;font-size: 14px;font-weight: bold;}
  .time span{border:1px solid #5856D6;height:20px;width:20px;border-radius: 5px;display: flex;justify-content: center;align-items: center;cursor: pointer;margin:0 14px;}
  .time span i{font-size:12px;font-weight: bold;}
  @media (max-width: 768px) {

  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {

  }
</style>
