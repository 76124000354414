import Vue from 'vue'
import { login, getUserInfo } from '@/network/user'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { MessageBox } from 'element-ui'

const user = {
  state: {
    showMenu: true,
    showMenuMobile:true,
    token: '',
    nickname: '',
    headimgurl: '',
    type: 0,
    uuid: ''
  },

  mutations: {
    SET_SHOW_MENU: (state, showMenu) => {
      state.showMenu = showMenu
    },
    SET_SHOW_MENU_MOBILE: (state, showMenuMobile) => {
      state.showMenuMobile = showMenuMobile
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname
    },
    SET_HEADIMGURL: (state, headimgurl) => {
      state.headimgurl = headimgurl
    },
    SET_USER_TYPE: (state, type) => {
      state.type = type
    },
    SET_USER_UUID: (state, uuid) => {
      state.uuid = uuid
    }
  },

  actions: {
    async Login({ commit }, params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then((response) => {
            if (response.code === 200) {
              const data = response.data
              // data.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhN2NlOTE4NC0wYTU1LTRkY2EtOTA5Ni00NjBlNjdlZTRhOGYiLCJhZG1pbnR5cGUiOiItMSIsImFkbWluIjpmYWxzZSwiZXhwIjoxNjk0MDcwMDkxLCJpYXQiOjE2OTIzNDIwOTF9.5y7SkqPqQp5D_5QfJ42ZDKrfiOL1nChIF5W2QuVh2SY'
              Vue.ls.set(ACCESS_TOKEN, data.token, 24 * 60 * 60 * 1000)
              commit('SET_TOKEN', data.token)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(res => {
            if (res.code == 200) {
              commit('SET_NICKNAME', res.data.company_name)
              if(res.data.public_files_uuid != null){
                commit('SET_HEADIMGURL', res.data.public_files_uuid)
              }
              /* commit('SET_USER_UUID', res.result.sub)
              if (res.result.profilePicture) {
                commit('SET_HEADIMGURL', res.result.profilePicture)
              } */
              resolve(res)
            } else {
              reject(res)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 登出
    Logout({ commit, state }) {
      commit('SET_NICKNAME', '')
      commit('SET_HEADIMGURL', '')
      commit('SET_TOKEN', '')
      commit('SET_USER_TYPE', 0)
      commit('SET_USER_UUID', '')
      commit('SET_NUMBER', 0)
      Vue.ls.remove(ACCESS_TOKEN)
    },
  },
}

export default user
