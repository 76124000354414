<template>
  <div class="login" v-loading="loading">
    <div class="bg">
      <img src="../assets/imgs/logo_white.png"/>
    </div>
    <div class="toolbar">
      <a class="acta" @click="type = 1">Password Reset</a>
    </div>
    <div class="form">
      <span>New password</span>
      <div class="div">
        <input placeholder="New password" v-model="password" type="password"/>
      </div>
      <div class="div" style="margin-top:20px;">
        <input placeholder="Password confirm" v-model="rePassword" type="password"/>
      </div>
      <div class="error" v-if="error_message != ''">{{error_message}}</div>
      <div class="btn" @click="reset_btn" v-if="client_account_uuid != '' && post_fix_url_uuid != ''">Reset</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { getActionApi,postActionApi } from '@/network/manageApi';
  import Vue from 'vue'


  export default {
    name: 'Password-reset',
    components: {
    },
    data() {
      return {
        type:0,
        key: '',
        loading:false,
        password: '',
        rePassword: '',
        error_message: '',
        client_account_uuid:'',
        post_fix_url_uuid:''
      }
    },
    computed: {

    },
    created() {
      this.key = this.$route.params.code
      this.check_key()
    },
    methods: {
      check_key() {
        this.loading = true
        getActionApi("/user-management/my-information/password/validation/" + this.key).then((res) => {
          this.client_account_uuid = res.data.client_account_uuid
          this.post_fix_url_uuid = res.data.post_fix_url_uuid
        }).catch((err) => {
          if(err.response.status == 400){
            this.$router.replace('/login')
          }else{
            this.error_message = err.response.data.details.data.message
          }
        }).finally((res) => {
          this.loading = false
        });
      },
      reset_btn() {
        let reg= /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]])[A-Za-z\d`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]]{6,18}$/;
        if(reg.test(this.password)) {
          if(this.rePassword != '') {
            if(this.rePassword == this.password) {
              this.error_message = ''
            } else {
              this.error_message = 'passwords do not match.'
              return
            }
          } else {
            this.error_message = ''
          }
        } else {
          this.error_message = 'combinations of alphanumeric and special symbols within 6 to 18 characters'
          return
        }
        let params = {client_account_uuid: this.client_account_uuid, password: this.password}
        this.loading = true
        this.error_message = ''
        postActionApi("/user-management/my-information/password/reset/"+this.post_fix_url_uuid, params).then((res) => {
          this.$router.replace('/login')
        }).catch((err) => {
          this.error_message = err.response.data.details.data.message
        }).finally((res) => {
          this.loading = false
        });
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .login{display: flex;justify-content: center;align-items: center;height:100vh;flex-direction: column;}
  .bg{position: fixed;top:0;left:calc(50vw + 160px);z-index: -1;align-items: center;justify-content: center;border-radius: 0 0 0 10px;min-width:500px;width:calc(50vw - 160px);height:90vh;display: flex;background-image: url(../assets/imgs/login_bg.jpg);background-size:auto 100%;}
  .bg img{height:94px;}
  .form{background-color: #ffffff;width:450px;box-shadow: 0px 6px 6px -1px rgba(0, 0, 0, 0.1);border-radius: 16px;padding:32px 42px;display: flex;flex-direction: column;}
  .form span{color:#A0AEC0;font-size:14px;margin-top:10px;margin-bottom:10px;}
  .form .div{border: 1px solid #CBD5E0;border-radius: 6px;overflow: hidden;padding:10px 14px;display: flex;align-items: center;}
  .form .act{border:1px solid #5856D6;}
  .toolbar{width:450px;display: flex;align-items: center;justify-content: center;}
  .toolbar a{cursor: pointer;font-size: 18px;line-height:52px;width: 160px;text-align: center;border-bottom:2px solid #ffffff;}
  .toolbar .acta{border-bottom:2px solid #5856D6;color:#5856D6;}
  .form input{border:none;height:20px;font-size:14px;width: 100%;}
  .error{color:#F56565;text-align: center;margin-top:10px;font-size: 14px;}
  .form .btn{height:52px;width:100%;background-color: #5856D6;cursor: pointer;color:#ffffff;font-size:17px;display: flex;align-items: center;justify-content: center;border-radius: 8px;margin:20px 0;}
  @media (max-width: 768px) {
    .bg{left:20px;width:calc(100vw - 40px);height:24vh;border-radius: 0 0 10px 10px;min-width:0;}
    .bg img{height:47px;}
    .toolbar{width:calc(100vw - 40px);}
    .form{width:calc(100vw - 40px);padding:20px 20px;border-radius: 10px;}
    .form h1{font-size:26px;}
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .bg{align-items: flex-start;justify-content: flex-start;height:80vh;}
    .bg img{margin:30px;height:47px;}
  }
</style>
